(function () {
    angular.module('informaApp')
        .component('pharmaPopup', {
            templateUrl: 'components/pharma-popup/template.html',
            controller: PharmaPopup,
            bindings: {
                targetElement: '<',
                getSource: '<'
            }
        });

    function PharmaPopup($element) {
        const onTargetElementClick = () => {
            if (!this.source) {
                setPopupOpacity($element, 0.8);
                this.popupLoading = true;

                this.getSource().then((data) => {
                    setPopupOpacity($element, 1);
                    this.source = data;
                    this.popupLoading = false;
                });
            }

            togglePopup($element, this.targetElement);
        };

        const onResize = () => {
            const [popup] = $element.find('.pharma-popup-container');

            movePopup(popup, this.targetElement);
        };

        const init = () => initialize($element, this.targetElement, onTargetElementClick, onResize);

        this.$onInit = () => {
            init();
        };

        this.$onChanges = (changes) => {
            if (changes.targetElement) {
                init();
            }
        };

        this.onDestroy = () => {
            this.targetElement.removeEventListener('click', onTargetElementClick);
            window.removeEventListener('resize', onResize);
        }
    }

    function initialize($element, targetElement, onTargetClick, onResize) {
        if (!targetElement) {
            return;
        }

        document.body.append($element[0]);

        bindClickOnTargetElement($element, targetElement, onTargetClick);
        bindClickOutsidePopup($element, targetElement);
        bindOnResize(onResize);

        bindOnScroll($element);
    }

    function bindOnScroll($element) {
        window.addEventListener('scroll', () => {
            const [popup] = $element.find('.pharma-popup-container');

            setDisplay(popup, false);
        });
    }

    function bindOnResize(callback) {
        window.addEventListener('resize', callback);
    }

    function bindClickOnTargetElement($element, targetElement, callback) {
        targetElement.removeEventListener('click', callback);

        targetElement.addEventListener('click', callback);
    }

    function bindClickOutsidePopup($element, targetElement) {
        const [popup] = $element.find('.pharma-popup-container');

        document.body.addEventListener('click', (event) => {
            const isParentPopup = $(event.target).closest('.pharma-popup-container').length;

            if (event.target === targetElement || event.target === popup || isParentPopup) {
                return;
            }

            setDisplay(popup, false);
        });
    }

    function togglePopup($element, targetElement) {
        const [popup] = $element.find('.pharma-popup-container');

        setDisplay(popup, popup.style.display === 'none');

        movePopup(popup, targetElement);
    }

    function movePopup(popup, targetElement) {
        const offset = calculateOffset(targetElement);

        popup.style.left = `${offset.left + targetElement.clientWidth}px`;
        popup.style.top =  `${offset.top - popup.clientHeight}px`;
    }

    function setDisplay(element, value) {
        element.style.display = value ? 'block' : 'none';
    }

    function calculateOffset(element, offset = {left: 0, top: 0}) {
        offset.left += element.offsetLeft;
        offset.top += element.offsetTop - (element.scrollTop || 0);

        return element.offsetParent
            ? calculateOffset(element.offsetParent, offset)
            : offset;
    }

    function setPopupOpacity($element, value) {
        $element.find('.pharma-popup-container').css('opacity', value);
    }
})();